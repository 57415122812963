import React, { ReactElement } from "react"
import "./FullPageWrapper.scss"

const FullPageWrapper = ({ children }: { children: React.ReactNode }): ReactElement => {
  return (
    <div className="FullPageWrapper">
      <div className="FullPageWrapper__Inner">{children}</div>
    </div>
  )
}

export default FullPageWrapper
