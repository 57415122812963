import React, { ReactElement } from "react"
import "./Hero.scss"

type Hero = {
  primaryHeader: string
  secondaryHeader: ReactElement
}

const Hero = ({ primaryHeader, secondaryHeader }: Hero) => {
  return (
    <div className="Hero">
      <h1>{primaryHeader}</h1>
      <h2>{secondaryHeader}</h2>
    </div>
  )
}

export default Hero
